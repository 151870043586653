<template>
  <div>
    <h2 class="icon__title">Icons</h2>
    <h3 class="icon__subtitle">Outline</h3>
    <ul class="icon__list">
      <li class="icon__item" v-for="(icon, index) in variablesOutline" :key="index">
        <i :class="`icon-${icon}`"></i>
      </li>
    </ul>
    <h3 class="icon__subtitle">Outline Fill</h3>
    <ul class="icon__list">
      <li class="icon__item" v-for="(icon, index) in variablesFill" :key="index">
        <i :class="`icon-${icon}`"></i>
      </li>
    </ul>
    <h3 class="icon__subtitle">Color</h3>
    <ul class="icon__list">
      <li class="icon__item" v-for="(icon, index) in variablesColor" :key="index">
        <IcoMoonWrap :name="icon.name" :pathCount="icon.path" />
      </li>
    </ul>
  </div>
</template>

<script>
import IcoMoonWrap from "@/assets/aop-icons/IcoMoonWrap";
export default {
  name: "IconPage",
  components: { IcoMoonWrap },
  props: {},
  data() {
    return {
      variablesOutline: [
        "3d-rotation",
        "alarm-add",
        "alarm-off",
        "alarm-on",
        "announcement",
        "clipboard-text",
        "clipboard-ind",
        "clipboard",
        "assignment-return",
        "assignment-returned",
        "clipboard-check",
        "autorenew",
        "backup",
        "bookmarks",
        "build",
        "close-fullscreen",
        "code",
        "compare-arrows",
        "dashboard",
        "date-range",
        "description",
        "explore",
        "explore-off",
        "extension",
        "heart",
        "filter",
        "fingerprint",
        "download-secondary",
        "help-center",
        "question",
        "http",
        "lightbulb",
        "lock-open",
        "login",
        "rule",
        "settings-ethernet",
        "shopping-bag",
        "source",
        "speaker-notes",
        "speaker-notes-off",
        "sticky-note",
        "subject",
        "subtitles-off",
        "support",
        "swap-circle",
        "system-update-alt",
        "tab",
        "table-view",
        "tab-unselected",
        "thumb-down",
        "thumbs-up-down",
        "thumb-up",
        "timeline",
        "toc",
        "today",
        "track-changes",
        "translate",
        "trending-down",
        "trending-up",
        "bookmark-outline",
        "unpublished",
        "upgrade",
        "verified",
        "verified-user",
        "vertical-split",
        "view-agenda",
        "view_array",
        "view-carousel",
        "view-column",
        "view-day",
        "view-list",
        "view-module",
        "view-quilt",
        "view-sidebar",
        "view-stream",
        "view-week",
        "eye-off",
        "voice-off",
        "clock",
        "work-off",
        "work-outline",
        "wysiwyg",
        "searched-for",
        "zoom-in",
        "zoom-out",
        "copy",
        "reader-mode",
        "mute",
        "arrow-back",
        "chain",
        "menu",
        "menu-pin",
        "filter-list",
        "test-check",
        "arrow-short",
        "cleaning",
        "lock-secondary",
        "menu-open",
        "volume-off",
        "bell",
        "group",
        "setting",
        "email",
        "channel",
        "gif",
        "people",
        "favorites",
        "anchor",
        "photo-library",
        "video-library",
        "forum",
        "arrow-enter",
        "entrance",
        "members-add",
        "duplicate",
        "edit-message",
        "repeat",
        "reply-arrow",
        "palette",
        "space",
        "user-add",
        "other",
        "fire",
        "circle-plus",
        "ballot",
        "circle-check",
        "message",
        "stars",
        "clipboard-secondary",
        "done",
        "paper-clip-secondary",
        "user",
        "error-secondary",
        "flag",
        "eye",
        "bug",
        "check-all",
        "double-left",
        "double-right",
        "bell1",
        "smile",
        "flash",
        "lightning1",
        "microphone",
        "diploma",
        "shortage",
        "time",
        "excess",
        "info",
        "lock",
        "exit",
        "sort",
        "search-secondary",
        "cross-in-round",
        "filter-secondary",
        "details",
        "calendar-secondary",
        "search",
        "edit",
        "delete",
        "emoji",
        "image",
        "send",
        "cross",
        "loader",
        "down",
        "left",
        "right",
        "up",
        "dots-vertical",
        "dots-horizontal",
        "paper-clip",
        "check-mark",
        "burger-bar",
        "sort-arrow",
        "chat-bar",
        "text",
        "file",
        "folder",
        "chat",
        "video",
        "rocket",
        "idea",
        "warning",
        "plus",
        "minus",
        "academic-cap",
        "book",
        "category",
        "calendar",
        "check",
        "check-circle",
        "document-text",
        "double-down",
        "double-up",
        "download",
        "global",
        "home",
        "archive",
        "unarchive",
        "night",
        "paint-bucket",
        "eye-closed",
        "sand-clock",
        "TagSimple",
        "to-share",
        "way",
        "edit-all",
        "bottom",
        "edit-bold",
        "edit-bullet-list",
        "edit-border-inside",
        "edit-border-center-hor",
        "edit-border-center-ver",
        "edit-border-left",
        "edit-center",
        "edit-justify",
        "edit-left",
        "edit-no-border",
        "edit-right",
        "edit-img-middle",
        "edit-border-right",
        "table",
        "edit-border-top",
        "edit-italic",
        "edit-img-top-center",
        "edit-img-top-left",
        "edit-img-top-left1",
        "edit-number",
        "edit-paint-bucket",
        "edit-strikethrough",
        "edit-underline",
        "edit-undo",
        "edit-return",
        "edit-img-add"
      ],
      variablesFill: [
        "heart-fill",
        "g-translate",
        "help",
        "wifi-protected-setup",
        "unpin",
        "improvement",
        "hand",
        "binoculars",
        "crown",
        "necktie",
        "writing-pen",
        "shovel",
        "work",
        "cake",
        "cap",
        "family",
        "landscape",
        "graduate-cap",
        "photo",
        "maternity-leave",
        "sports-bar",
        "horn",
        "lightning",
        "fastfood",
        "black-sphere",
        "error1",
        "warning1",
        "down-secondary",
        "comments",
        "bandage",
        "laptop",
        "sunset",
        "grid"
      ],
      variablesColor: [
        {
          name: "video-player",
          path: 11
        },
        {
          name: "picture",
          path: 3
        },
        {
          name: "calendar-status-yes",
          path: 2
        },
        {
          name: "calendar-status-no",
          path: 2
        },
        {
          name: "calendar-status-maybe",
          path: 2
        },
        {
          name: "clipboard-show",
          path: 4
        },
        {
          name: "align-left",
          path: 4
        },
        {
          name: "logo",
          path: 3
        },
        {
          name: "avatar-default",
          path: 2
        },
        {
          name: "xls",
          path: 7
        },
        {
          name: "ppt",
          path: 7
        },
        {
          name: "pdf",
          path: 7
        },
        {
          name: "doc",
          path: 7
        }
      ]
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.icon {
  &__title,
  &__subtitle {
    margin: 0 0 24px;
    color: $text-default;
  }

  &__subtitle {
    padding: 0 0 16px;
    border-bottom: 1px solid $border-disabled;
  }

  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0 0 24px;
  }

  &__item {
    margin: 0 16px 16px 0;
  }

  i {
    font-size: 32px;
    line-height: 36px;
  }
}
</style>
